import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

const MenuMobile = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? "open" : ""}`}
    >
      <ul>
        {menuLinks.map((menuItem) =>
          menuItem.link ? (
            <li key={menuItem.name}>
              <Link to={menuItem.link}>{menuItem.name}</Link>
            </li>
          ) : (
            menuItem.dropdown.map((link) => (
              <li key={link.name}>
                <Link to={link.link}>{link.name}</Link>
              </li>
            ))
          )
        )}
      </ul>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              # dropdown {
              #   name
              #   link
              # }
            }
          }
        }
      }
    `}
    render={(data) => <MenuMobile active={props.active} data={data} />}
  />
);
