import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon/favicon.ico';
import appleFavicon from '../images/favicon/apple-touch-icon.png';

const SEO = (props) => {
	// function injectOpenPanel() {
	// 	if (typeof window !== 'undefined') {
	// 		window.op =
	// 			window.op ||
	// 			function (...args) {
	// 				(window.op.q = window.op.q || []).push(args);
	// 			};

	// 		window.op('ctor', {
	// 			clientId: 'ae3566ad-9e2e-44f6-8005-607d7795fc68',
	// 			trackScreenViews: true,
	// 			trackOutgoingLinks: true,
	// 			trackAttributes: true,
	// 		});
	// 	}
	// }
	// const injectGA = () => {
	// 	if (typeof window !== 'undefined') {
	// 		window.dataLayer = window.dataLayer || [];
	// 		function gtag() {
	// 			window.dataLayer.push(arguments);
	// 		}
	// 		gtag('js', new Date());

	// 		gtag('config', 'G-HRXDTPXKG4');
	// 	}
	// };
	// make sure it points at langi as the canonical URL
	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				const metaDescription =
					props.description || data.site.siteMetadata.description;
				const title = props.title || data.site.siteMetadata.title;
				const { siteUrl } = data.site.siteMetadata;
				const image = props.image ? `${siteUrl}${props.image}` : null;
				const url = props.url ? props.url : siteUrl;
				const canonicalUrl = props.canonicalUrl ? props.canonicalUrl : url;
				// expire mailchimp closed popup cookie if it's present
				// if (typeof window !== "undefined") {
				//   const mailchimpCookie = document.cookie;
				//   if (mailchimpCookie === "MCPopupClosed=yes") {
				//     document.cookie =
				//       "MCPopupClosed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
				//   }
				// }

				const metaArray = [
					{
						name: 'p:domain_verify',
						content: '34cabc2b5e676e6517f5b13035613ea4',
					},
					{ name: 'monetization', content: '$ilp.uphold.com/EELJMjLDw2HL' },
					{ name: 'description', content: metaDescription },
					{ property: 'og:url', content: url },
					{ property: 'og:site_name', content: 'HowToVietnamese' },
					{ property: 'fb:app_id', content: '307375426879459' },
					{ property: 'og:type', content: 'article' },
					{ property: 'og:image:secure_url', content: image },
					{ property: 'og:image', content: image },
					{ property: 'twitter:image', content: image },
					{ property: 'og:image:width', content: '1200' },
					{ property: 'og:image:height', content: '630' },
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: metaDescription },
					{ name: 'twitter:card', content: 'summary' },
					{
						name: 'google-site-verification',
						content: 'yKOO5Au7sGEc3--hdLxvn5S3R-aMMkp11Pl-6kxvPdA',
					},
				];

				// add here the url of pages you don't want to be indexed
				const noIndexUrl = [
					'https://howtovietnamese.com/vietnamese-patterns-book1-audio-evpb1acp',
					'https://howtovietnamese.com/vietnamese-patterns-book2-audio-evpb2acp',
					'https://howtovietnamese.com/class-booking',
				];

				if (noIndexUrl.indexOf(url) !== -1) {
					metaArray.push({ name: 'robots', content: 'noindex' });
				}
				return (
					<Helmet
						htmlAttributes={{
							lang: 'en',
						}}
						url={url}
						title={title}
						image={image}
						titleTemplate={`${title}`}
						meta={metaArray
							.concat(
								props.keywords.length > 0
									? {
											name: 'keywords',
											content: props.keywords.join(', '),
									  }
									: []
							)
							.concat(props.meta)}
					>
						<link rel="canonical" href={canonicalUrl} />
						<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
						<link
							rel="apple-touch-icon"
							data-react-helmet="true"
							sizes="180x180"
							href={appleFavicon}
						/>
						<script
							type="text/javascript"
							src="https://payhip.com/payhip.js"
						></script>
						<script src="https://storage.ko-fi.com/cdn/scripts/overlay-widget.js"></script>
						<script
							type="text/javascript"
							src="https://storage.ko-fi.com/cdn/widget/Widget_2.js"
						></script>
						<script
							defer="defer"
							src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
							integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
							crossOrigin="anonymous"
						/>
						<script
							defer="defer"
							src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
							integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
							crossOrigin="anonymous"
						/>
						<script
							defer="defer"
							src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
							integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
							crossOrigin="anonymous"
						/>
						<script
							async
							src="https://www.googletagmanager.com/gtag/js?id=G-HRXDTPXKG4"
						></script>
						<script src="https://openpanel.dev/op.js" defer async />

						{/* <script>{injectOpenPanel()}</script> */}
						{/* <script>{injectGA()}</script> */}
						{/* google youtube embed subscribe */}
						<script async defer src="https://apis.google.com/js/platform.js" />

						{/* PayHip */}

						{/* <script
            type="text/javascript"
            dangerouslySetInnerHTML={`var PayhipConfig = {enableCart: true};`}
          ></script> */}

						{/* pinterest */}
						<script async defer src="//assets.pinterest.com/js/pinit.js" />
						{}
					</Helmet>
				);
			}}
		/>
	);
};

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	keywords: [],
};

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
	}
`;
