import React from "react";
import { Link } from "gatsby";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import logo from "../images/logo.png";
import MenuMobile from "./MenuMobile";
import { navigate } from "gatsby";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const mobileLogo = {
  height: "40px",
  width: "40px",
};

const desktopLogo = {
  height: "50px",
  width: "50px",
};
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = (menuActive) => {
    this.setState((prevState) => ({
      menuActive: !prevState.menuActive,
    }));
  };

  trackEvent = (e) => {
    e.preventDefault();
    trackCustomEvent({
      // track the click
      category: "header_cta",
      action: "Click",
      label: "header_cta",
      value: "/learning",
    });
    navigate("/learning");
  };

  render() {
    return (
      <div className="header">
        <div className="container mt-0 d-flex justify-content-center align-items-center flex-column">
          <div className="row fullWidth">
            <div className="col-12 col-md-11 d-flex flex-row align-items-center justify-content-between pr-0 pl-0">
              <div className="logo">
                <Link to="/">
                  <img
                    style={desktopLogo}
                    alt="howtovietnamese logo"
                    src={logo}
                  />
                </Link>
              </div>
              <div className="logo-mobile">
                <Link to="/">
                  <img
                    style={mobileLogo}
                    alt="howtovietnamese logo"
                    src={logo}
                  />
                </Link>
              </div>
              <MenuMobile active={this.state.menuActive} />
              <Menu />
              {/* hidden on mobile, see mobileHeaderCTA for the mobile version of this */}
              {/* <Link
                to="/learning"
                className="btn-primary box-shadow hide-on-mobile"
              >
                Free Vietnamese Lessons
              </Link> */}
              <button
                onClick={(e) => this.trackEvent(e)}
                className="btn-simple btn-primary-solid btn-blue-solid box-shadow "
              >
                <b>→ FREE</b>
                &nbsp;Vietnamese Courses
              </button>
              <Hamburger toggleMenu={this.toggleMenu} />
            </div>
          </div>
          {/* end first row */}
          {/* <div className="row hide-on-desktop">
            <div className="col-12 col-md-11 d-flex flex-row align-items-center justify-content-center pr-md-0 pl-md-0"></div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Header;
