import React from 'react';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LHTBanner from '../components/LHTBanner';
import '../scss/style.scss';
// import CookieConsent from 'react-cookie-consent'

const Layout = (props) => {
	if (typeof window !== 'undefined' && window.innerWidth > 720) {
		setTimeout(() => {
			if (window.kofiWidgetOverlay) {
				// on larger screen have a floating widget
				window.kofiWidgetOverlay.draw('howtovietnamese', {
					type: 'floating-chat',
					'floating-chat.donateButton.text': 'Support me',
					'floating-chat.donateButton.background-color': '#00b9fe',
					'floating-chat.donateButton.text-color': '#fff',
				});
			}
		}, 1000);
	}
	return (
		<React.Fragment>
			<SEO />
			<LHTBanner />

			<div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
				<div id="wrapper" className="wrapper">
					{/* <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="gatsby-gdpr-google-analytics"
          >
            We use cookies to offer you personalized content and to analyse our traffic. Please accept cookies for the best experience on our website.
          </CookieConsent> */}
					<Header />
					{props.children}
				</div>
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Layout;
