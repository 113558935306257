import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

function LHTBanner() {
	const [isOpen, setIsOpen] = useState(false);

	function toggleBannerStatus(value) {
		sessionStorage.setItem('lhtBanner', value ? 'open' : 'collapsed');
		setIsOpen(value);
	}
	useEffect(() => {
		// this is to remember that it should be open when they navigate a different page as the component will unmount
		if (typeof window !== 'undefined') {
			const bannerStatus = sessionStorage.getItem('lhtBanner');
			if (bannerStatus) {
				if (bannerStatus === 'open') {
					setIsOpen(true);
				} else {
					setIsOpen(false);
				}
			} else {
				// it's a new session where a banner wasn't iniitiated yet
				setTimeout(() => {
					setIsOpen(true);
				}, 10000);
			}
		}
	}, []);

	return (
		<>
			<div
				className={`col ${isOpen ? 'banner-open' : 'banner-closed'}`}
				id="lhtBanner"
			>
				<div id="lhtBanner-container">
					<div id="lhtBanner-header">
						<p id="lhtBanner-title">
							<span>Read 420+ Stories with Audio & Transcript</span>
							{isOpen ? (
								<FaChevronDown
									onClick={() => toggleBannerStatus(false)}
								></FaChevronDown>
							) : (
								<FaChevronUp
									onClick={() => toggleBannerStatus(true)}
								></FaChevronUp>
							)}
						</p>
					</div>
					{/* // body */}
					{isOpen ? (
						<div id="lhtBanner-body">
							<p>
								❤️ Improve your reading, listening and acquire vocabulary
								efficiently with graded stories (A1-B2)
							</p>
							<video
								autoPlay
								loop
								playsInline
								muted
								preload="none"
								id="lhtBanner-video"
							>
								<source
									src="https://languagehowto-videos.b-cdn.net/clip-1.webm"
									type="video/webm"
								></source>
								{/* // safari likes this one */}
								<source
									src="https://languagehowto-videos.b-cdn.net/clip-1.mp4"
									type="video/mp4"
								></source>
								<source
									src="https://languagehowto-videos.b-cdn.net/clip-1.mov"
									type="video/mov"
								></source>
								Video not playable
							</video>
							<OutboundLink
								target="_blank"
								id="lhtBanner-btn"
								className=" box-shadow align-center"
								href="https://langi.app"
							>
								Try Free Stories →
							</OutboundLink>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	);
}

export default LHTBanner;
