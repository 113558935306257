import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

const Menu = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {menuLinks.map((menuItem) =>
          menuItem.dropdown ? (
            <React.Fragment>
              <button
                type="button"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {menuItem.name}
              </button>
              <div className="dropdown-menu" id="dropdown-menu">
                {menuItem.dropdown.map((link) => (
                  <Link className="dropdown-item" to={link.link}>
                    {link.name}
                  </Link>
                ))}
              </div>
            </React.Fragment>
          ) : (
            //   <Dropdown>
            //   <Dropdown.Toggle variant="" id="dropdown-basic">
            //     {menuItem.name}
            //   </Dropdown.Toggle>

            //   <Dropdown.Menu>
            //     {menuItem.dropdown.map(link => (
            //         <Link className="dropdown-item" to={link.link}>{link.name}</Link>
            //     ))}
            //   </Dropdown.Menu>
            // </Dropdown>
            <li key={menuItem.name}>
              <Link to={menuItem.link}>{menuItem.name}</Link>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              # dropdown {
              #   name
              #   link
              # }
              link
            }
          }
        }
      }
    `}
    render={(data) => <Menu data={data} />}
  />
);
