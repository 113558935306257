import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import pinterest from "../images/pinterest.png";
import podcast from "../images/podcast.png";
import youtube from "../images/youtube.png";
import emailIcon from "../images/icons/email.svg";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Footer = (props) => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        {/* first col logo and contact */}

        {/* <p>LEARN BY LEVEL</p>
           <Link to="/newbie">Newbie</Link> 
          <Link to="/courses">Beginner</Link>
          <Link to="/intermediate">Intermediate</Link> 
        
        */}

        {/* second col pages */}
        <div className="footer-column footer-social col-11 col-md-3">
          <p>LEARNING</p>
          {/* <Link to="/vietnamese-class">Tutoring</Link> */}
          <Link to="/learning">Online Courses</Link>
          <Link to="/learning">BookStore</Link>
          <Link to="/blog">Blog</Link>
        </div>

        {/* third col social */}
        <div className="footer-column footer-pages col-11 col-md-3">
          <p>STAY CONNECTED</p>
          <Link to="/youtube">YouTube</Link>
          <Link to="/podcast">Podcast</Link>
          {/* <OutboundLink
            target="_blank"
            href={props.data.site.siteMetadata.contact.youtube}
          >
            Youtube
          </OutboundLink> */}
          {/* <OutboundLink
            target="_blank"
            href={props.data.site.siteMetadata.contact.podcast}
          >
            Podcast
          </OutboundLink> */}
          <OutboundLink
            target="_blank"
            href={props.data.site.siteMetadata.contact.pinterest}
          >
            Pinterest
          </OutboundLink>
          <Link to="/contact">Contact</Link>
        </div>

        <div className="footer-column footer-contacts col-11 col-md-3"></div>

        {/* fourth col social */}
        <div className="footer-column footer-social col-11 col-md-3">
          <div className="pb-2">
            <span className="footer-social">
              <OutboundLink
                target="_blank"
                href={props.data.site.siteMetadata.contact.youtube}
              >
                <img src={youtube} alt="HowToVietnamese" />
              </OutboundLink>
            </span>

            <span className="footer-social">
              <OutboundLink
                target="_blank"
                href={props.data.site.siteMetadata.contact.podcast}
              >
                <img src={podcast} alt="anchor.fm/howtovietnamese" />
              </OutboundLink>
            </span>

            <span className="footer-social">
              <OutboundLink
                target="_blank"
                href={props.data.site.siteMetadata.contact.pinterest}
              >
                <img src={pinterest} alt="pinterest.com/howtovietnamese" />
              </OutboundLink>
            </span>

            <span className="footer-social">
              <OutboundLink
                target="_blank"
                href={"mailto:" + props.data.site.siteMetadata.contact.email}
              >
                <img src={emailIcon} alt="email" />
              </OutboundLink>
            </span>
          </div>
          <span>{props.data.site.siteMetadata.contact.address}</span>
          <span className="copyright">
            © {new Date().getFullYear()}{" "}
            {props.data.site.siteMetadata.copyright}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            copyright
            menuLinks {
              name
              link
            }
            contact {
              address
              youtube
              podcast
              pinterest
              email
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
